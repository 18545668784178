// 支付宝账号预验证入口
<template>
  <div class="content">
    <van-nav-bar
      class="nar-bar-info"
      title="支付宝账号验证"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="verificationFn">
      <div class="pre-verification">
        <div class="title">
          <!-- 界面中蓝色竖条 -->
          <span></span>
          中国大陆身份信息
        </div>
        <div style="margin-bottom: 1.875rem">
          <van-field
            v-model.trim="alipayInfo.userName"
            name="姓名"
            label="姓名"
            placeholder="请输入您的姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            v-model.trim="alipayInfo.idCardValue"
            name="身份证号"
            label="身份证号"
            placeholder="请输入您的身份证号"
            :rules="[{ required: true, message: '请填写身份证号' }]"
          />
        </div>
        <div class="title">
          <span></span>
          支付宝账户信息
        </div>
        <van-field
          v-model.trim="alipayInfo.alipayAccount"
          name="支付宝账号"
          label="支付宝账号"
          placeholder="请输入您的支付宝账号"
          :rules="[{ required: true, message: '请填写支付宝账号' }]"
        />
      </div>
      <div class="btn-submit">
        <van-button class="submit" type="primary" native-type="submit"
          >提交信息
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { createAlipayVerification } from "../api/index.js";
import { Dialog } from "vant";

export default defineComponent({
  setup() {
    const router = useRouter();
    const alipayForm = reactive({
      alipayInfo: {
        userName: "", //姓名
        idCardValue: "", //证件号
        alipayAccount: "", //支付宝账号
      },
      dialogShow: false,
      errorMsg: "",
    });
    const verificationFn = () => {
      let _params = alipayForm.alipayInfo;
      createAlipayVerification(_params).then((res) => {
        if (res.code == 200) {
          window.location.href = res.data;
        } else if (res.code == 10001) {
          router.push({
            path: "/validationResults",
            query: res.data,
          });
        } else {
          Dialog({
            title: "提示",
            message: res.message,
            confirmButtonColor: "#468cff",
          });
        }
      });
    };
    const onClickLeft = () => {};
    return {
      ...toRefs(alipayForm),
      verificationFn,
      onClickLeft,
    };
  },
});
</script>
<style scoped>
</style>
