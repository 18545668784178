// import * as request from '../utils/request'
import request from '../utils/request'

//预验证接口
export function createAlipayVerification (data) {
  return request({
    url: '/busiServer/alipay/verification/createAlipayVerification',
    data,
    method: 'post'
  })
}

//获取本地支付宝信息
export function getUserMsg (params) {
  return request({
    url: '/busiServer/alipay/verification/getUserMsg',
    params,
    method: 'get'
  })
}

//验证
export function authAndVerify (params) {
  return request({
    url: '/busiServer/alipay/verification/authAndVerify',
    params,
    method: 'get'
  })
}

//保存创客信息
export function saveThirdBusiUser(data) {
  return request({
    url: '/rest/third/busiuser/saveThirdBusiUser',
    data,
    method: 'post'
  })
}
