import { createRouter, createWebHistory } from 'vue-router'
import alipayInfoCollection from '../views/alipayInfoCollection.vue'
import wxInfoVerfication from '../views/wxInfoVerfication.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'alipayInfoCollection',
  //   component: alipayInfoCollection
  // },
  {
    path: '/',
    name: 'wxInfoVerfication',
    component: wxInfoVerfication
  },
  {
    path: '/alipayInfoVerification',
    name: 'alipayInfoVerification',
    component: () => import(/* webpackChunkName: "about" */ '../views/alipayInfoVerification.vue')
  },
  {
    path: '/validationResults',
    name: 'validationResults',
    component: () => import(/* webpackChunkName: "about" */ '../views/validationResults.vue')
  }
  ,
  {
    path: '/selfEmployedIndex',
    name: 'selfEmployedIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/selfEmployedIndex.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
