<template>
  <router-view />
</template>

<style>
#app {
  height: 100vh;
  background-color: #ffffff;
}
.pre-verification {
  margin-top: 0.625rem;
  padding: 1.25rem;
}
.title {
  display: flex;
  align-items: center;
}
.title span {
  display: inline-block;
  margin-right: 0.625rem;
  height: 0.625rem;
  border: 0.125rem solid #468cff;
}

.form-item {
  margin-left: 0.625rem;
}

.form-item div {
  margin: 0.625rem 0;
}

.form-item label {
  color: #666666;
}
.btn-submit {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  padding: 5%;
}

.btn-submit .submit {
  width: 100%;
}
/* 修改默认导航样式 */
.nar-bar-info {
  background-color: #468cff;
}
.van-nav-bar__title {
  color: #fff;
}
.van-nav-bar .van-icon {
  color: #fff;
  font-size: 1rem;
}
.van-cell:last-child::after {
  display: block;
}
</style>
