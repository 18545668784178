import { createApp } from 'vue'
import router from './router'
import 'vant/lib/index.css';
import App from './App.vue'
import { Button, NavBar, NoticeBar, Form, Field, Dialog, Toast,Checkbox,CellGroup } from 'vant';

createApp(App)
    .use(Button)
    .use(NavBar)
    .use(NoticeBar)
    .use(Form)
    .use(Field)
    .use(Dialog)
    .use(Toast)
    .use(router)
    .use(Checkbox)
    .use(CellGroup)
    .mount('#app')
