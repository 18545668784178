// 支付宝账号预验证入口
<template>
  <div class="content">

    <van-nav-bar
      class="nar-bar-info"
      title="嗨活平台用户注册"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="saveUserInfoFn">
      <div class="pre-verification">
        <div class="title">
          <!-- 界面中蓝色竖条 -->
          <span></span>
          中国大陆身份信息
        </div>
        <div style="margin-bottom: 1.875rem">
          <van-field
            v-model.trim="userInfo.fullName"
            name="姓名"
            label="姓名"
            placeholder="请输入您的姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            v-model.trim="userInfo.idcardNumber"
            name="身份证号"
            label="身份证号"
            placeholder="请输入您的身份证号"
            maxlength="18"
            :rules="[{  required: true, message: '请填写身份证号' }]"
          />
          <van-field
            v-model.trim="userInfo.mobile"
            name="手机号"
            label="手机号"
            maxlength="11"
            placeholder="请输入您的手机号"
            :rules="[{  validator: verifyPhone, message: '请填写正确的手机号' }]"
          />


        </div>
        <!--        <div class="title">-->
        <!--          <span></span>-->
        <!--          微信账户信息-->
        <!--        </div>-->
        <!--        <van-field-->
        <!--          v-model.trim="alipayInfo.alipayAccount"-->
        <!--          name="微信账号"-->
        <!--          label="微信账号"-->
        <!--          placeholder="请输入您的微信账号"-->
        <!--          :rules="[{ required: true, message: '请填写微信账号' }]"-->
        <!--        />-->
      </div>


      <div class="pre-verification">
        <div class="title">
          <!-- 界面中蓝色竖条 -->
          <span></span>
          税源公司
        </div>
        <div style="margin-bottom: 1.875rem">
          <van-field label="公司名称" placeholder="九江谷仓信息科技有限公司" readonly/>
        </div>

      </div>

      <div style="display: flex;width: 100%;align-content: center;justify-content: center;margin-top: 20px">
        <img class="img-wh" src="../../src/assets/img/img.png">
      </div>


      <div class="btn-submit">
        <div class="tip-box" style="display: flex;flex-direction: row ">
          <van-checkbox v-model="userInfo.checked" id="checked" shape="square" checked-color="#cb421e"></van-checkbox>
          <label style="margin-left: 10px">
            <div class="tips">
              已阅读并同意协议
              <u><span @click="linkpolicyFn">《自雇者协议》</span></u>
            </div>
          </label>
        </div>
        <van-button class="submit" type="primary" native-type="submit"
        >提交信息
        </van-button>
      </div>


      <!--      <div class="tip-box row">-->
      <!--        <van-checkbox v-model="alipayInfo.checked" id="checked" shape="square" checked-color="#cb421e">-->
      <!--          <label for="checked">-->
      <!--            <div class="tips">已阅读并同意协议</div>-->
      <!--          </label>-->
      <!--        </van-checkbox>-->
      <!--      </div>-->

      <!--      <div class="btn-submit">-->
      <!--        <van-button class="submit" type="primary" native-type="submit"-->
      <!--        >提交信息-->
      <!--        </van-button>-->
      <!--      </div>-->
    </van-form>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import {useRouter} from "vue-router";
import {createAlipayVerification, saveThirdBusiUser} from "../api/index.js";
import {Dialog, Checkbox, Toast} from "vant";


export default defineComponent({
  setup() {
    const router = useRouter();
    const userForm = reactive({
      userInfo: {
        fullName: "", //姓名
        idcardNumber: "", //证件号
        mobile: "",//手机号
        platformName: "第三方创客",
        platformVersion: "prod",
        corpCode: "GC001153",
        corpName: "蜂助手股份有限公司",
        taxCorpName: "九江谷仓信息科技有限公司",
        checked: false,

        // alipayAccount: "", //支付宝账号
        // checked: false,//自雇者签署
      },
      dialogShow: false,
      errorMsg: "",
    });

    const saveUserInfoFn = () => {
      let _params = userForm.userInfo;
      if (userForm.userInfo.checked == false) {
        Toast("请先阅读并同意自雇者协议")
        return
      }
      saveThirdBusiUser(_params).then((res) => {
        Toast(res.msg)
      })
    };

    const verificationFn = () => {
      let _params = userForm.userInfo;
      createAlipayVerification(_params).then((res) => {
        if (res.code == 200) {
          window.location.href = res.data;
        } else if (res.code == 10001) {
          router.push({
            path: "/validationResults",
            query: res.data,
          });
        } else {
          Dialog({
            title: "提示",
            message: res.message,
            confirmButtonColor: "#468cff",
          });
        }
      });
    };
    const linkpolicyFn = () => {
      // console.log("wo yao kan ziguzhexieyi")
      // router.push("/agreementDoc");
      router.push({
        path: "/selfEmployedIndex",
      });
    };
    const onClickLeft = () => {
    };

    const verifyPhone = (val) => {
      return /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/.test(val); // 返回true或false
    };


    return {
      ...toRefs(userForm),
      verificationFn,
      onClickLeft,
      linkpolicyFn,
      verifyPhone,
      saveUserInfoFn,
    };
  },
});
</script>
<style scoped>
.tip-box {
  font-size: 0.75rem;
  line-height: 2.3125rem;
}

.tip-box .tips {
  color: #666666;
  line-height: 0.625rem;
  padding: 0.9375rem 0;
  /* margin-left: 0.3125rem; */
}

.img-wh {
  width: 14rem;
  height: 8rem;
}

</style>
