/* eslint-disable no-unused-vars */
//import db from './localstorage'
import axios from "axios";
import {
    Toast
} from "vant";


console.log(process.env.VUE_APP_API_BASE_URL);
// 统一配置,

let FEBS_REQUEST = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // api 的 base_url
    responseType: 'json',
    validateStatus(status) {
        // 200 外的状态码都认定为失败
        return status === 200
    }
})
// let token = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0Ml9LZEt0WXRJLWxNMG5ZeHNWMHR6T01Jd2F0Ujl4emN5aUREcURXZnVRREZNcWJpYkRydG0xcl9tY0NDMDVHeWIzbWlRQWN2ejBPZW9UQXZJRzhCdHhHSnhUbHRMcGVHdXVNUEFpaUZhR2UzOCIsImp0aSI6ImNhNjQ3MmUzLWE5NGYtNDFhMC04MjY0LWY0YjdlYTBmZTYxMCIsImlhdCI6MTYxNDU4ODUxMSwiZXhwIjoxNjE3MTgwNTExfQ.wKjmh7-2YCRGXGNyFr1ESR9wYUCiYI2Fq1EVcjfjIdk'

// // http request 拦截器
FEBS_REQUEST.interceptors.request.use(
    config => {
        // 判断是否存在token，如果存在的话，则每个http header都加上tokendb("HEADER_AUTHORIZATION")
        // config.headers.Authorization = token;
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
// http response 拦截器
FEBS_REQUEST.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if (response.data.code == 200) {
                return response.data;
            } else if (response.data.code == 10018003) {
                return window.location.href = ''
            } else {
                Toast(response.data.message);
                return response.data;
            }
        } else {
            Toast(response.data.message);
        }
    },
    error => {
        let code = 0;
        try {
            code = error.response.code;
        } catch (e) {
            if (error.toString().indexOf("Error: timeout") !== -1) {
                Toast({
                    message: "网络请求超时",
                    duration: 2500
                });
                return Promise.reject(error);
            }
            if (error.toString().indexOf("Error: Network Error") !== -1) {
                Toast({
                    message: "网络请求错误",
                    duration: 2500
                });
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);
export default FEBS_REQUEST
